export default async function (this: any, event) {
    console.log('districtId handler', event?.features)
    const firstFeature = event?.features?.[0]
    if (!firstFeature) return
    const selectedDistrictName = firstFeature.properties.name
    this.selectedDistrictName = selectedDistrictName

    //TODO: MARK of new tiles loading
    // const districtId = firstFeature.id
    const districtId = firstFeature.properties.id
    this.gasificationPlan = []
    this.isGasificationPlanLoading = true
    const gasificationPlan = await this.$axios
        .get(`/gasification/plan/district/${districtId}`)
        .then(response => response?.data)
        .catch(error => {
            console.error(error)
        })
    this.isGasificationPlanLoading = false

    if (gasificationPlan?.length) {
        this.gasificationPlan = gasificationPlan
    } else {
        this.gasificationPlan = []
    }
    // console.log('gasificationPlan:', gasificationPlan)
}
