<template>
    <div>
        <f7-toolbar
            v-if="gasificationPlan.length"
            tabbar
            scrollable
            position="top"
        >
            <div :class="$style.gasificationYearTriggers">
                <f7-button
                    v-for="(
                        plan, gasificationPlanYear
                    ) of gasificationPlanNormalized"
                    :key="gasificationPlanYear"
                    :class="$style.gasificationYearTrigger"
                    outline
                    :fill="gasificationPlanYear === selectedYear"
                    @click="selectYear(gasificationPlanYear)"
                    >{{ gasificationPlanYear }}</f7-button
                >
            </div>
        </f7-toolbar>
        <el-scrollbar>
            <!-- <f7-toolbar position="top"> </f7-toolbar> -->
            <!-- <f7-subnavbar>
        Нажмите на регион или район для получения плана по догазификации
    </f7-subnavbar> -->
            <f7-card
                outline
                :style="{
                    padding: 'var(--indent2)',
                }"
                :class="$style.gasificationPlanCard"
            >
                Нажмите на регион или район для получения плана по догазификации
            </f7-card>
            <!-- <f7-block-title>План газификации</f7-block-title> -->

            <f7-block-title v-if="selectedDistrictName">{{
                selectedDistrictName
            }}</f7-block-title>
            <!-- <f7-block v-else :class="$style.blockText">
        Нажмите на регион или район для получения плана по догазификации
    </f7-block> -->
            <f7-card
                v-if="selectedDistrictName"
                outline
                :style="{
                    ...(!isShowGasificationPlan && {
                        padding: 'var(--indent2)',
                    }),
                }"
                :class="$style.gasificationPlanCard"
            >
                <f7-preloader v-if="isGasificationPlanLoading" />
                <el-scrollbar
                    v-else-if="gasificationPlan.length"
                    class="data-table"
                >
                    <table>
                        <thead>
                            <tr>
                                <th class="label-cell">Населенный пункт</th>
                                <th class="numeric-cell">Не газифицированно</th>
                                <th class="numeric-cell">Планируется</th>
                                <th class="numeric-cell">Срок догазификации</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(
                                    gasificationArea, index
                                ) of gasificationPlanNormalized[selectedYear]"
                                :key="index"
                            >
                                <td class="label-cell">
                                    {{ gasificationArea.areaName }}
                                </td>
                                <td class="label-cell">
                                    {{ gasificationArea.nonGasifiedBuilding }}
                                </td>
                                <td class="label-cell">
                                    {{ gasificationArea.gasifiedBuildingCount }}
                                </td>
                                <td class="label-cell">
                                    {{ gasificationArea.term }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-scrollbar>

                <template v-else>
                    Нет данных по плану догазификации района
                </template>

                <!-- <el-affix :offset="220">
                <el-button type="primary"></el-button>
            </el-affix> -->
            </f7-card>
        </el-scrollbar>
    </div>
</template>
<script>
import { TILE_DISTRICT_SOURCE_LAYER } from '@/shared/constants'

import districtClickHandler from './methods/districtClickHandler'
export default {
    name: 'GasificationPlan',
    components: {},
    data() {
        return {
            selectedDistrictName: '',
            selectedYear: 0,
            gasificationPlan: [],
            isGasificationPlanLoading: false,
        }
    },
    computed: {
        map() {
            return this.$map
        },
        isShowGasificationPlan() {
            return (
                !this.isGasificationPlanLoading && this.gasificationPlan.length
            )
        },
        gasificationPlanNormalized() {
            return this.gasificationPlan.reduce(
                (accumulator, gasificationPlanArea) => {
                    const areaPlan = gasificationPlanArea.plan
                    const areaName = gasificationPlanArea.name
                    let nonGasifiedBuilding =
                        gasificationPlanArea.nonGasifiedBuilding
                    areaPlan.forEach((areaPlanYear, index) => {
                        const year = areaPlanYear.year
                        const gasifiedBuildingCount =
                            areaPlanYear.gasifiedBuildingCount
                        let accumulatorYear = accumulator[year]

                        if (!accumulatorYear) {
                            accumulatorYear = []
                            accumulator[year] = accumulatorYear
                        }
                        if (index > 0) {
                            nonGasifiedBuilding -=
                                areaPlan[index - 1].gasifiedBuildingCount
                        }

                        const beginningOfWorkMonth =
                            areaPlanYear.beginningOfWorkMonth
                        const completionOfWorkMonth =
                            areaPlanYear.completionOfWorkMonth
                        let term = ''
                        if (beginningOfWorkMonth && completionOfWorkMonth) {
                            term = `${beginningOfWorkMonth} - ${completionOfWorkMonth}`
                        } else if (beginningOfWorkMonth) {
                            term = beginningOfWorkMonth
                        } else if (completionOfWorkMonth) {
                            term = completionOfWorkMonth
                        }
                        accumulatorYear.push({
                            areaName,
                            nonGasifiedBuilding,
                            gasifiedBuildingCount,
                            term,
                        })
                    })
                    return accumulator
                },
                {}
            )
        },
    },
    methods: {
        selectYear(gasificationPlanYear) {
            this.selectedYear = gasificationPlanYear
        },

        districtClickHandler,
    },
    mounted() {
        this.$watch(
            'gasificationPlanNormalized',
            gasificationPlanNormalized => {
                if (!gasificationPlanNormalized) return
                this.selectedYear = Object.keys(gasificationPlanNormalized)[0]
            }
        )
    },
    watch: {
        map(mapInstance) {
            if (mapInstance) {
                mapInstance.on('load', () => {
                    this.$map.on(
                        'click',
                        TILE_DISTRICT_SOURCE_LAYER,
                        this.districtClickHandler
                    )
                })
            }
        },
    },
    beforeUnmount() {
        this.$map?.off(
            'click',
            TILE_DISTRICT_SOURCE_LAYER,
            this.districtClickHandler
        )
    },
}
</script>

<style lang="stylus" module>
.gasificationYearTriggers
    display flex
    min-width 100%
.gasificationYearTrigger
    width 100%
    &:not(:first-child)
        margin-left var(--indent2)

.gasificationPlanCard
    display flex
    justify-content center
</style>
